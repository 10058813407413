export default (items, currentSlide, visibleThumbnails) => {
  if (items.length < visibleThumbnails) {
    return 0
  }

  if (
    items.length - visibleThumbnails >= 0 &&
    currentSlide >= items.length - visibleThumbnails
  ) {
    return items.length - visibleThumbnails
  }
  return currentSlide
}

import styled, { css } from "styled-components"
import { ButtonBack, ButtonNext } from "pure-react-carousel"
import { transparentize } from "polished"
import Image from "components/atoms/Image"

const textStyles = css`
  padding: 0 ${({ theme }) => theme.layout.mobilePadding};
  margin-top: 1.6rem;
  word-break: break-word;
  overflow-wrap: break-word;
  hyphens: auto;
  text-align: justify;
`

const titleStyles = css`
  ${textStyles}
  font-weight: ${({ theme }) => theme.font.weight.semiBold};
  font-size: ${({ theme }) => theme.font.size.md};
`

const StyledImage = styled(Image)`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const StyledImageWrapper = styled.div`
  margin: 0 auto;
  max-width: 76.8rem;
  height: 100%;

  ${({ theme }) => theme.mq.medium} {
    max-width: ${({ theme }) => theme.gridWidth};
  }
`

const StyledPlayButton = styled.div`
  width: 7.2rem;
  height: 7.2rem;
  position: absolute;
  left: 0;
  top: 50%;
  right: 0;
  margin: 0 auto;
  transform: translateY(-50%);
  background: ${({ theme }) => transparentize(0.52, theme.color.secondary01)};

  border-radius: 50%;
  border: 0.2rem solid ${({ theme }) => theme.color.white};
  transition: border-color 0.15s linear;
`

const StyledTriangle = styled.div`
  position: absolute;
  left: 0.6rem;
  top: 50%;
  right: 0;
  margin: 0 auto;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-top: 1rem solid transparent;
  border-bottom: 1rem solid transparent;

  border-left: 1.6rem solid white;

  transition: border-color 0.15s linear;
`

const StyledCrumbsWrapper = styled.div`
  display: flex;
  z-index: 2;
  color: ${({ theme }) => theme.color.black};
  font-size: ${({ theme }) => theme.font.size.s};
  align-items: center;

  > span {
    height: 1px;
    width: 2rem;
    display: block;
    background-color: ${({ theme }) => theme.color.black};
    margin: 0 0.5rem;
  }
`

const StyledButtonWrapper = styled.div`
  display: flex;
  margin-left: auto;
`

const carouselButtonsStyles = css`
  border: none;
  background: ${({ theme }) => theme.color.secondary05};
  height: 4.8rem;
  width: 4.8rem;
  padding: 0;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  & svg {
    width: 1.8rem;
    height: 1.8rem;
    fill: ${({ theme }) => theme.color.black};
  }
`

const StyledButtonBack = styled(ButtonBack)`
  ${carouselButtonsStyles}
  right: 4.8rem;
`

const StyledButtonNext = styled(ButtonNext)`
  ${carouselButtonsStyles}
  right: 0;
  background: ${({ theme }) => theme.color.septenary03};

  & svg {
    fill: ${({ theme }) => theme.color.white};
  }
`
const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  position: absolute;
  bottom: 5.5rem;
  padding: 1.6rem;
`

const StyledCaptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 18.5rem);
  overflow: scroll;
  margin-bottom: 8rem;
`

const StyledCarouselWrapper = styled.div`
  position: relative;
`

export {
  textStyles,
  titleStyles,
  StyledImage,
  StyledImageWrapper,
  StyledCrumbsWrapper,
  StyledCaptionWrapper,
  StyledButtonWrapper,
  StyledButtonBack,
  StyledButtonNext,
  StyledPlayButton,
  StyledTriangle,
  StyledWrapper,
  StyledCarouselWrapper,
}

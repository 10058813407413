import React, { useState } from "react"
import PropTypes from "prop-types"
import paths from "utils/paths"
import { useIntl } from "gatsby-plugin-intl"
import trackCustomEventTrigger from "utils/trackCustomEventTrigger"
import trackingCategoriesHelper from "utils/trackingCategoriesHelper"

import SideListItem from "components/atoms/SideListItem"
import Modal from "components/atoms/Modal"
import BtnLink from "components/atoms/BtnLink"
import getMediaUrl from "utils/getMediaUrl"

import ChevronLeft from "assets/icons/chevron-left.svg"

import {
  linkButtonStyles,
  StyledStageNavigation,
  StyledBackButton,
  StyledTitle,
  StyledIcon,
  customStageListStyles,
} from "./MobileProjectBriefing.styles"

import MobileBriefingStageDetails from "./MobileBriefingStageDetails"

const MobileProjectBriefing = ({ briefingStages, projectSlug }) => {
  const [activeStage, setActiveStage] = useState(null)
  const [isModalVisible, setIsModalVisible] = useState(false)

  const { formatMessage } = useIntl()
  const { portfolioProjectBriefing } = paths

  const openModal = (briefingStage) => {
    setActiveStage(briefingStage)
    setIsModalVisible(true)
  }
  const handleCloseModal = () => setIsModalVisible(false)

  const stageMobileNavigation = () => (
    <StyledStageNavigation>
      <StyledBackButton>
        <BtnLink
          extraStyles={linkButtonStyles}
          to={`${portfolioProjectBriefing(projectSlug)}`}
          onClick={handleCloseModal}
        >
          <ChevronLeft />
          {formatMessage({
            id: "back",
          })}
        </BtnLink>
      </StyledBackButton>
      <StyledTitle>{activeStage.name}</StyledTitle>
    </StyledStageNavigation>
  )

  return (
    <>
      {briefingStages &&
        briefingStages.map((briefingStage) => (
          <SideListItem
            onClick={() => {
              openModal(briefingStage)
              trackCustomEventTrigger({
                category: trackingCategoriesHelper.PROJECT_BRIEFING,
                action: "Click",
                label: `${trackingCategoriesHelper.PROJECT_BRIEFING} link(${briefingStage.name})`,
              })
            }}
            key={briefingStage.name}
            extraStyles={customStageListStyles}
          >
            {briefingStage.icon && (
              <StyledIcon
                type="image/svg+xml"
                data={getMediaUrl(briefingStage.icon)}
              />
            )}
            {briefingStage.name}
          </SideListItem>
        ))}
      <Modal showModal={isModalVisible} handleCloseModal={handleCloseModal}>
        {activeStage && stageMobileNavigation()}
        <MobileBriefingStageDetails activeStage={activeStage} />
      </Modal>
    </>
  )
}

MobileProjectBriefing.propTypes = {
  briefingStages: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      slides: PropTypes.arrayOf(
        PropTypes.shape({
          caption: PropTypes.string,
          title: PropTypes.string,
          file: PropTypes.string,
          mediaType: PropTypes.string,
          videoPoster: PropTypes.oneOfType([
            PropTypes.shape({
              original: PropTypes.string,
              large: PropTypes.string,
              medium: PropTypes.string,
              small: PropTypes.string,
            }),
            PropTypes.string,
          ]),
          videoUrl: PropTypes.string,
        })
      ),
    })
  ).isRequired,
  projectSlug: PropTypes.string.isRequired,
}

export default MobileProjectBriefing

import React, { useState } from "react"
import PropTypes from "prop-types"
import { CarouselProvider, Slider, Slide } from "pure-react-carousel"
import ReactPlayer from "react-player/lazy"

import getMediaUrl from "utils/getMediaUrl"
import getReactPlayerPlaceholder from "utils/getReactPlayerPlaceholder"

import P from "components/atoms/P"
import ProgressBar from "components/atoms/ProgressBar"

import ChevronLeft from "assets/icons/chevron-left.svg"
import ChevronRight from "assets/icons/chevron-right.svg"

import {
  textStyles,
  titleStyles,
  StyledImage,
  StyledImageWrapper,
  StyledCrumbsWrapper,
  StyledCaptionWrapper,
  StyledButtonWrapper,
  StyledButtonBack,
  StyledButtonNext,
  StyledPlayButton,
  StyledTriangle,
  StyledWrapper,
  StyledCarouselWrapper,
} from "./MobileBriefingStageDetails.styles"

const MobileBriefingStageDetails = ({ activeStage }) => {
  const [currentSlide, setCurrentSlide] = useState(0)

  const decreaseSlide = () => setCurrentSlide(currentSlide - 1)
  const increaseSlide = () => setCurrentSlide(currentSlide + 1)

  return (
    <>
      <ProgressBar
        isActive
        completed={currentSlide + 1}
        max={activeStage.slides.length}
      />
      <StyledCaptionWrapper>
        <CarouselProvider
          naturalSlideWidth={100}
          naturalSlideHeight={50}
          totalSlides={activeStage.slides.length}
          touchEnabled={false}
          dragEnabled={false}
          disableKeyboard
        >
          <StyledCarouselWrapper>
            <Slider>
              {activeStage.slides.map((element, index) => (
                <Slide index={index} key={element.title}>
                  {element.mediaType === "IMAGE" && element.file && (
                    <StyledImageWrapper>
                      <StyledImage src={element.file} alt={element.title} />
                    </StyledImageWrapper>
                  )}
                  {element.mediaType === "VIDEO" && element.videoUrl && (
                    <ReactPlayer
                      url={getMediaUrl(element.videoUrl)}
                      light={getReactPlayerPlaceholder(element.videoPoster)}
                      width="100%"
                      height="100%"
                      controls
                      playing
                      playIcon={
                        <StyledPlayButton>
                          <StyledTriangle />
                        </StyledPlayButton>
                      }
                    />
                  )}
                </Slide>
              ))}
            </Slider>
          </StyledCarouselWrapper>

          <P extraStyles={titleStyles}>
            {activeStage.slides[currentSlide].title}
          </P>
          {activeStage.slides[currentSlide].caption && (
            <P extraStyles={textStyles}>
              {activeStage.slides[currentSlide].caption}
            </P>
          )}
          <StyledWrapper>
            <StyledCrumbsWrapper>
              {currentSlide + 1} <span /> {activeStage.slides.length}
            </StyledCrumbsWrapper>
            {activeStage.slides.length > 1 && (
              <>
                <StyledButtonWrapper>
                  <StyledButtonBack onClick={decreaseSlide}>
                    <ChevronLeft />
                  </StyledButtonBack>
                  <StyledButtonNext onClick={increaseSlide}>
                    <ChevronRight />
                  </StyledButtonNext>
                </StyledButtonWrapper>
              </>
            )}
          </StyledWrapper>
        </CarouselProvider>
      </StyledCaptionWrapper>
    </>
  )
}

MobileBriefingStageDetails.propTypes = {
  activeStage: PropTypes.shape({
    slides: PropTypes.arrayOf(
      PropTypes.shape({
        caption: PropTypes.string,
        title: PropTypes.string,
        file: PropTypes.string,
        mediaType: PropTypes.string,
        videoPoster: PropTypes.oneOfType([
          PropTypes.shape({
            original: PropTypes.string,
            large: PropTypes.string,
            medium: PropTypes.string,
            small: PropTypes.string,
          }),
          PropTypes.string,
        ]),
        videoUrl: PropTypes.string,
      })
    ),
  }).isRequired,
}

export default MobileBriefingStageDetails

import React from "react"
import PropTypes from "prop-types"

import useWindowSize from "utils/useWindowSize"
import theme from "styles/theme"

import DesktopProjectBriefing from "./DesktopProjectBriefing"
import MobileProjectBriefing from "./MobileProjectBriefing"

const ProjectBriefing = ({ sectionData, projectSlug }) => {
  const size = useWindowSize()

  return (
    <>
      {size.width >= theme.breakpoints.medium ? (
        <DesktopProjectBriefing briefingStages={sectionData} />
      ) : (
        <MobileProjectBriefing
          briefingStages={sectionData}
          projectSlug={projectSlug}
        />
      )}
    </>
  )
}

ProjectBriefing.propTypes = {
  sectionData: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      icon: PropTypes.oneOfType([
        PropTypes.shape({
          original: PropTypes.string,
          large: PropTypes.string,
          medium: PropTypes.string,
          small: PropTypes.string,
        }),
        PropTypes.string,
      ]),
      slides: PropTypes.arrayOf(
        PropTypes.shape({
          caption: PropTypes.string,
          title: PropTypes.string,
          file: PropTypes.oneOfType([
            PropTypes.shape({
              original: PropTypes.string,
              large: PropTypes.string,
              medium: PropTypes.string,
              small: PropTypes.string,
            }),
            PropTypes.string,
          ]),
          mediaType: PropTypes.string,
          videoPoster: PropTypes.oneOfType([
            PropTypes.shape({
              original: PropTypes.string,
              large: PropTypes.string,
              medium: PropTypes.string,
              small: PropTypes.string,
            }),
            PropTypes.string,
          ]),
          videoUrl: PropTypes.string,
        })
      ),
    })
  ).isRequired,
  projectSlug: PropTypes.string.isRequired,
}

export default ProjectBriefing

import styled from "styled-components"

const StyledProgressBarContainer = styled.div`
  height: 0.3rem;
  width: 100%;
  background-color: ${({ theme }) => theme.color.septenary04};
  visibility: ${({ isActive }) => (isActive ? "visible" : "hidden")};
`

const StyledProgressBarFiller = styled.div`
  height: 100%;
  width: ${({ percentage }) => `${percentage}%`};
  background-color: ${({ theme }) => theme.color.septenary03};
  border-radius: inherit;
  text-align: right;
`

export { StyledProgressBarContainer, StyledProgressBarFiller }

import React from "react"
import PropTypes from "prop-types"
import {
  StyledProgressBarContainer,
  StyledProgressBarFiller,
} from "./ProgressBar.styles"

const ProgressBar = ({ completed, max, isActive }) => {
  const getPercentage = ((completed / max) * 100).toFixed()

  return (
    <StyledProgressBarContainer isActive={isActive}>
      <StyledProgressBarFiller percentage={getPercentage} />
    </StyledProgressBarContainer>
  )
}

ProgressBar.propTypes = {
  completed: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  isActive: PropTypes.bool,
}

ProgressBar.defaultProps = {
  isActive: false,
}

export default ProgressBar

import React, { useState, useEffect, useRef } from "react"
import PropTypes from "prop-types"

import getMediaUrl from "utils/getMediaUrl"
import handleCurrentThumbnails from "utils/carouselThumbnailsHelper"
import getReactPlayerPlaceholder from "utils/getReactPlayerPlaceholder"
import trackCustomEventTrigger from "utils/trackCustomEventTrigger"
import trackingCategoriesHelper from "utils/trackingCategoriesHelper"

import ProgressBar from "components/atoms/ProgressBar"
import SideListItem from "components/atoms/SideListItem"
import P from "components/atoms/P"
import { CarouselProvider, Slider, Slide, Dot } from "pure-react-carousel"

import ChevronLeft from "assets/icons/chevron-left.svg"
import ChevronRight from "assets/icons/chevron-right.svg"

import {
  titleStyles,
  captionStyles,
  StyledSection,
  StyledTabsWrapper,
  StyledContentWrapper,
  StyledContentColumn,
  StyledCrumbsWrapper,
  StyledButtonBack,
  StyledButtonNext,
  StyledBlock,
  StyledImage,
  StyledImageWrapper,
  StyledWrapper,
  StyledCaptionWrapper,
  StyledDotContainer,
  StyledThumbnailsWrapper,
  StyledButtonWrapper,
  StyledPlayButton,
  StyledTriangle,
  StyledColumnWrapper,
  StyledThumbnailButtonBack,
  StyledThumbnailButtonNext,
  StyledIcon,
  StyledButtonContainer,
  StyledCaptionContainer,
  customStageListStyles,
  StyledReactPlayer,
} from "./DesktopProjectBriefing.styles"

const DesktopProjectBriefing = ({ briefingStages }) => {
  const [activeStage, setActiveStage] = useState(briefingStages[0])
  const [currentSlide, setCurrentSlide] = useState(0)
  const [playVideo, setPlayVideo] = useState(true)
  const carouselRef = useRef(null)
  const visibleThumbnails = 3

  const decreaseSlide = () => {
    setPlayVideo(false)
    setCurrentSlide(currentSlide - 1)
    trackCustomEventTrigger({
      category: trackingCategoriesHelper.PROJECT_BRIEFING,
      action: "Click",
      label: `${trackingCategoriesHelper.PROJECT_BRIEFING} ${activeStage.name} stage button(backwards)`,
    })
  }
  const increaseSlide = () => {
    setPlayVideo(false)
    setCurrentSlide(currentSlide + 1)
    trackCustomEventTrigger({
      category: trackingCategoriesHelper.PROJECT_BRIEFING,
      action: "Click",
      label: `${trackingCategoriesHelper.PROJECT_BRIEFING} ${activeStage.name} stage button(forward)`,
    })
  }

  const setSlide = (idx) => {
    setPlayVideo(false)
    setCurrentSlide(idx)
  }

  const handleActiveStage = (briefingStage) => {
    setSlide(0)
    setActiveStage(briefingStage)
  }

  const handleKeyDown = (event) => {
    const { carouselStore } = carouselRef.current
    const { state } = carouselStore
    const { key } = event

    if (
      (key === "ArrowRight" || key === "d") &&
      state.currentSlide < activeStage.slides.length - 1
    ) {
      carouselStore.setStoreState({
        ...state,
        currentSlide: state.currentSlide + 1,
      })
      increaseSlide()
    }
    if ((key === "ArrowLeft" || key === "a") && state.currentSlide >= 1) {
      carouselStore.setStoreState({
        ...state,
        currentSlide: state.currentSlide - 1,
      })
      decreaseSlide()
    }
  }

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown)
    return () => {
      document.removeEventListener("keydown", handleKeyDown)
    }
  }, [currentSlide, activeStage])

  const renderBriefingStage = (briefingStage) => (
    <SideListItem
      isActive={activeStage.name === briefingStage.name}
      onClick={() => {
        handleActiveStage(briefingStage)
        trackCustomEventTrigger({
          category: trackingCategoriesHelper.PROJECT_BRIEFING,
          action: "Click",
          label: `${trackingCategoriesHelper.PROJECT_BRIEFING} link(${briefingStage.name})`,
        })
      }}
      key={briefingStage.name}
      extraStyles={customStageListStyles}
    >
      {briefingStage.icon && (
        <StyledIcon
          type="image/svg+xml"
          data={getMediaUrl(briefingStage.icon)}
        />
      )}

      {briefingStage.name}
    </SideListItem>
  )

  return (
    <StyledSection>
      <>
        <StyledTabsWrapper>
          {briefingStages &&
            briefingStages.map((stage) => renderBriefingStage(stage))}
        </StyledTabsWrapper>
        <StyledContentWrapper>
          <StyledWrapper>
            <CarouselProvider
              ref={carouselRef}
              naturalSlideWidth={100}
              naturalSlideHeight={86}
              totalSlides={activeStage.slides.length}
              touchEnabled={false}
              dragEnabled={false}
              currentSlide={0}
              key={activeStage.name}
            >
              <StyledColumnWrapper>
                <StyledContentColumn>
                  <Slider>
                    {activeStage.slides.map((element, index) => (
                      <Slide index={index} key={element.title}>
                        {element.mediaType === "IMAGE" && element.file && (
                          <StyledImageWrapper>
                            <StyledImage
                              src={element.file}
                              alt={element.title}
                            />
                          </StyledImageWrapper>
                        )}
                        {element.mediaType === "VIDEO" && element.videoUrl && (
                          <StyledReactPlayer
                            url={getMediaUrl(element.videoUrl)}
                            light={getReactPlayerPlaceholder(
                              element.videoPoster
                            )}
                            width="100%"
                            height="100%"
                            controls
                            playing={playVideo || currentSlide === index}
                            playIcon={
                              <StyledPlayButton>
                                <StyledTriangle />
                              </StyledPlayButton>
                            }
                          />
                        )}
                      </Slide>
                    ))}
                  </Slider>
                  {activeStage.slides.length > 1 && (
                    <StyledDotContainer
                      currentSlide={handleCurrentThumbnails(
                        activeStage.slides,
                        currentSlide,
                        visibleThumbnails
                      )}
                    >
                      <StyledThumbnailButtonBack
                        onClick={decreaseSlide}
                        disabled={currentSlide === 0}
                      >
                        <ChevronLeft />
                      </StyledThumbnailButtonBack>
                      <StyledThumbnailsWrapper>
                        {activeStage.slides.map((element, index) => (
                          <Dot
                            slide={index}
                            onClick={() => setSlide(index)}
                            key={element.title}
                          >
                            {element.mediaType === "VIDEO" &&
                              element.videoPoster && (
                                <StyledImage src={element.videoPoster} alt="" />
                              )}
                            {element.mediaType === "IMAGE" && element.file && (
                              <StyledImage src={element.file} alt="" />
                            )}
                          </Dot>
                        ))}
                      </StyledThumbnailsWrapper>
                      <StyledThumbnailButtonNext
                        onClick={increaseSlide}
                        disabled={
                          currentSlide === activeStage.slides.length - 1
                        }
                      >
                        <ChevronRight />
                      </StyledThumbnailButtonNext>
                    </StyledDotContainer>
                  )}
                </StyledContentColumn>
                <StyledContentColumn>
                  <ProgressBar
                    isActive={!!activeStage.name}
                    completed={currentSlide + 1}
                    max={activeStage.slides.length}
                  />
                  <StyledBlock slidesLength={activeStage.slides.length}>
                    <StyledCaptionWrapper
                      slidesLength={activeStage.slides.length}
                    >
                      <StyledCaptionContainer>
                        {activeStage.slides[currentSlide].title && (
                          <P extraStyles={titleStyles}>
                            {activeStage.slides[currentSlide].title}
                          </P>
                        )}
                        {activeStage.slides[currentSlide].caption && (
                          <P extraStyles={captionStyles}>
                            {activeStage.slides[currentSlide].caption}
                          </P>
                        )}
                      </StyledCaptionContainer>
                    </StyledCaptionWrapper>
                    <StyledButtonContainer>
                      {activeStage.slides.length > 1 && (
                        <>
                          <StyledCrumbsWrapper>
                            {currentSlide + 1} <span />{" "}
                            {activeStage.slides.length}
                          </StyledCrumbsWrapper>
                          <StyledButtonWrapper>
                            <StyledButtonBack
                              onClick={decreaseSlide}
                              disabled={currentSlide === 0}
                              lastslide={
                                currentSlide === activeStage.slides.length - 1
                                  ? "true"
                                  : undefined
                              }
                            >
                              <ChevronLeft />
                            </StyledButtonBack>
                            <StyledButtonNext
                              onClick={increaseSlide}
                              disabled={
                                currentSlide === activeStage.slides.length - 1
                              }
                            >
                              <ChevronRight />
                            </StyledButtonNext>
                          </StyledButtonWrapper>
                        </>
                      )}
                    </StyledButtonContainer>
                  </StyledBlock>
                </StyledContentColumn>
              </StyledColumnWrapper>
            </CarouselProvider>
          </StyledWrapper>
        </StyledContentWrapper>
      </>
    </StyledSection>
  )
}

DesktopProjectBriefing.propTypes = {
  briefingStages: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      slides: PropTypes.arrayOf(
        PropTypes.shape({
          caption: PropTypes.string,
          title: PropTypes.string,
          file: PropTypes.oneOfType([
            PropTypes.shape({
              original: PropTypes.string,
              large: PropTypes.string,
              medium: PropTypes.string,
              small: PropTypes.string,
            }),
            PropTypes.string,
          ]),
          mediaType: PropTypes.string,
          videoPoster: PropTypes.oneOfType([
            PropTypes.shape({
              original: PropTypes.string,
              large: PropTypes.string,
              medium: PropTypes.string,
              small: PropTypes.string,
            }),
            PropTypes.string,
          ]),
          videoUrl: PropTypes.string,
        })
      ),
    })
  ).isRequired,
}

export default DesktopProjectBriefing

import React from "react"
import { graphql } from "gatsby"
import { isEmptyRedirect } from "utils/redirect"
import PropTypes from "prop-types"
import SEO from "components/molecules/SEO"
import Layout from "components/molecules/Layout"
import PortfolioNavigation from "components/molecules/PortfolioNavigation"
import ProjectBriefing from "components/content/Portfolio/ProjectBriefing"
import ClientOnly from "components/atoms/ClientOnly"

const PortfolioBriefingPage = ({
  data: {
    api: { project },
  },
  pageContext: { language },
  location,
}) => {
  const { briefing } = project || {}
  isEmptyRedirect(briefing)

  return (
    <Layout hideFooter isNavStatic language={language}>
      <SEO title="Project briefing" />
      {briefing && (
        <ClientOnly>
          <PortfolioNavigation projectData={project} location={location} />
          <ProjectBriefing sectionData={briefing} projectSlug={project.slug} />
        </ClientOnly>
      )}
    </Layout>
  )
}

PortfolioBriefingPage.propTypes = {
  data: PropTypes.shape({
    api: PropTypes.shape({
      project: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        slug: PropTypes.string,
        briefing: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string,
            icon: PropTypes.oneOfType([
              PropTypes.shape({
                original: PropTypes.string,
                large: PropTypes.string,
                medium: PropTypes.string,
                small: PropTypes.string,
              }),
              PropTypes.string,
            ]),
            slides: PropTypes.arrayOf(
              PropTypes.shape({
                caption: PropTypes.string,
                title: PropTypes.string,
                file: PropTypes.oneOfType([
                  PropTypes.shape({
                    original: PropTypes.string,
                    large: PropTypes.string,
                    medium: PropTypes.string,
                    small: PropTypes.string,
                  }),
                  PropTypes.string,
                ]),
                mediaType: PropTypes.string,
                videoPoster: PropTypes.oneOfType([
                  PropTypes.shape({
                    original: PropTypes.string,
                    large: PropTypes.string,
                    medium: PropTypes.string,
                    small: PropTypes.string,
                  }),
                  PropTypes.string,
                ]),
                videoUrl: PropTypes.string,
              })
            ),
          })
        ),
      }),
    }),
  }).isRequired,
  pageContext: PropTypes.shape({
    language: PropTypes.string,
  }).isRequired,
  location: PropTypes.shape({
    state: PropTypes.shape({
      selectedPropertyType: PropTypes.string,
      selectedProjectType: PropTypes.string,
    }),
  }).isRequired,
}

// TODO update when BE ready
export const query = graphql`
  query PortfolioBriefingPageData(
    $apiLocale: API_Locale
    $projectSlug: String!
  ) {
    api {
      project(slug: $projectSlug, language: $apiLocale) {
        id
        name
        slug
        briefing {
          name
          icon
          slides {
            caption
            title
            file
            mediaType
            videoPoster
            videoUrl
          }
        }

        ...projectTabs
      }
    }
  }
`

export default PortfolioBriefingPage

import styled, { css } from "styled-components"

const linkButtonStyles = css`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.color.secondary01};
  cursor: pointer;

  &:hover,
  &:active {
    text-decoration: underline;
  }
  &:active {
    color: ${({ theme }) => theme.color.secondary01};
  }
`

const StyledStageNavigation = styled.div`
  height: 4.8rem;
  display: flex;
  align-items: center;
  position: relative;
`

const StyledBackButton = styled.div`
  position: absolute;
  left: 1.6rem;
  font-size: ${({ theme }) => theme.font.size.xs};
  font-weight: ${({ theme }) => theme.font.weight.semiBold};
  display: flex;

  & svg {
    height: 1.4rem;
    fill: ${({ theme }) => theme.color.secondary02};
  }

  &:hover,
  &:active {
    & svg {
      fill: ${({ theme }) => theme.color.secondary01};
    }
  }
`

const StyledTitle = styled.span`
  max-width: 50%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 auto;
  color: ${({ theme }) => theme.color.secondary01};
`
const StyledIcon = styled.object`
  width: 1.6rem;
  height: 1.6rem;
  margin-right: 1.6rem;
`
const customStageListStyles = css`
  > div {
    display: flex;
    align-items: center;

    object {
      filter: invert(39%) sepia(13%) saturate(1065%) hue-rotate(178deg)
        brightness(92%) contrast(86%);
      transition: filter 0.5s;
    }
  }
`

export {
  linkButtonStyles,
  StyledStageNavigation,
  StyledBackButton,
  StyledTitle,
  StyledIcon,
  customStageListStyles,
}

import styled, { css } from "styled-components"
import { ButtonBack, ButtonNext } from "pure-react-carousel"
import { transparentize } from "polished"
import Image from "components/atoms/Image"
import ReactPlayer from "react-player/lazy"

const titleStyles = css`
  font-weight: ${({ theme }) => theme.font.weight.semiBold};
  font-size: ${({ theme }) => theme.font.size.md};
  margin-bottom: 1.6rem;
  word-break: break-word;
  overflow-wrap: break-word;
`

const captionStyles = css`
  word-break: break-word;
  overflow-wrap: break-word;
  hyphens: auto;
  text-align: justify;
`

const StyledSection = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  max-width: ${({ theme }) => theme.gridWidth};
  width: 100%;
  height: 57.6rem;
  margin: 1.6rem auto;
  padding: 0 ${({ theme }) => theme.layout.mobilePadding};
`

const StyledTabsWrapper = styled.nav`
  display: flex;
  flex-flow: column;
  flex: 0 0 26.8rem;

  height: 100%;
  overflow: auto;

  margin-right: 1.6rem;
`

const StyledContentWrapper = styled.div`
  flex: 0 1 86.8rem;
  display: flex;

  ${({ theme }) => theme.mq.medium} {
    flex-direction: column;
    overflow: auto;
  }

  ${({ theme }) => theme.mq.large} {
    flex-direction: row;
    margin-left: 1.6rem;
    overflow: initial;
  }
`

const StyledContentColumn = styled.div`
  width: 100%;
  height: auto;
  position: relative;

  &:nth-of-type(2) {
    max-width: 32rem;
    padding-left: 3.2rem;
  }
`

const StyledCrumbsWrapper = styled.div`
  display: flex;
  z-index: 2;
  color: ${({ theme }) => theme.color.black};
  font-size: ${({ theme }) => theme.font.size.s};
  align-items: center;

  > span {
    height: 1px;
    width: 2rem;
    display: block;
    background-color: ${({ theme }) => theme.color.black};
    margin: 0 0.5rem;
  }
`

const carouselButtonsStyles = css`
  border: none;
  background: ${({ theme }) => theme.color.secondary05};
  height: 4.8rem;
  width: 4.8rem;
  padding: 0;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  & svg {
    width: 1.8rem;
    height: 1.8rem;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      & svg {
        fill: ${({ theme }) => theme.color.secondary04};
      }
    `};
`

const StyledButtonBack = styled(ButtonBack)`
  & svg {
    fill: ${({ theme }) => theme.color.black};
  }

  ${carouselButtonsStyles};
  right: 4.8rem;

  &:hover {
    background: ${({ theme }) => theme.color.secondary04};
  }

  ${({ lastslide }) =>
    lastslide === "true" &&
    css`
      background: ${({ theme }) => theme.color.septenary03};
      & svg {
        fill: ${({ theme }) => theme.color.white};
      }
      &:hover {
        background: ${({ theme }) => theme.color.septenary02};
      }
    `};

  ${({ disabled }) =>
    disabled &&
    css`
      &,
      &:hover {
        cursor: default;
        background: ${({ theme }) => theme.color.secondary05};
      }
    `};
`
const StyledButtonNext = styled(ButtonNext)`
  & svg {
    fill: ${({ theme }) => theme.color.white};
  }

  ${carouselButtonsStyles};

  right: 0;
  background: ${({ theme }) => theme.color.septenary03};

  &:hover {
    background: ${({ theme }) => theme.color.septenary02};
  }

  ${({ disabled }) =>
    disabled &&
    css`
      &,
      &:hover {
        cursor: default;
        background: ${({ theme }) => theme.color.secondary05};
      }
    `};
`

const StyledWrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: ${({ theme }) => theme.gridWidth};
  margin: 0 auto;

  .carousel__slider {
    min-height: 36rem;
  }
`

const StyledBlock = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  height: calc(100% - 0.3rem);
  ${({ slidesLength }) => slidesLength > 1 && `height: calc(100% - 10.7rem);`};
`

const StyledImageWrapper = styled.div`
  width: 100%;
  height: 100%;
`

const StyledImage = styled(Image)`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const StyledCaptionWrapper = styled.div`
  ${({ slidesLength }) =>
    slidesLength > 1 ? `height: calc(100% - 4.8rem);` : `height: 100%;`};
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1.6rem 0;
`

const StyledDotContainer = styled.div`
  width: 100%;
  max-width: 54.4rem;
  display: flex;
  margin: 2.4rem auto 0;
  justify-content: center;

  & .carousel__dot {
    width: 13.6rem;
    height: 8rem;
    padding: 0;
    border: 0;
    margin-right: 1.6rem;
    flex-shrink: 0;
    position: relative;
    overflow: hidden;

    transition: transform 300ms ease-in;
    ${({ currentSlide }) =>
      currentSlide &&
      css`
        transform: translateX(
          calc(-100% * ${currentSlide} - 1.6rem * ${currentSlide})
        );
      `};

    &:before {
      display: block;
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      background: ${({ theme }) => theme.color.white};
      transition: opacity 300ms linear;
      opacity: 0.6;
      top: 0;
      z-index: 1;
    }

    &:hover {
      &:before {
        opacity: 0;
      }
    }

    &.carousel__dot--selected {
      &:before {
        display: none;
      }
    }

    &:last-child {
      margin-right: 0;
    }

    & img {
      transition: all 300ms linear !important;
    }

    &:hover img {
      transform: scale(1.1);
    }
  }

  & .carousel__back-button,
  & .carousel__next-button {
    border: 0;
    background: transparent;
    outline: none;

    & > svg {
      fill: ${({ theme }) => theme.color.secondary02};
      width: 2rem;
      height: 2rem;

      &:hover {
        fill: ${({ theme }) => theme.color.secondary01};
      }
    }
  }

  & .carousel__back-button {
    margin-right: 1.6rem;
  }
  & .carousel__next-button {
    margin-left: 1.6rem;
  }
`

const StyledThumbnailsWrapper = styled.div`
  display: flex;
  overflow: hidden;
`

const StyledButtonWrapper = styled.div`
  display: flex;
  margin-left: auto;
`

const StyledPlayButton = styled.div`
  width: 7.2rem;
  height: 7.2rem;
  position: absolute;
  left: 0;
  top: 50%;
  right: 0;
  margin: 0 auto;
  transform: translateY(-50%);
  background: ${({ theme }) => transparentize(0.52, theme.color.secondary01)};

  border-radius: 50%;
  border: 0.2rem solid ${({ theme }) => theme.color.white};
  transition: border-color 0.15s linear;
`

const StyledTriangle = styled.div`
  position: absolute;
  left: 0.6rem;
  top: 50%;
  right: 0;
  margin: 0 auto;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-top: 1rem solid transparent;
  border-bottom: 1rem solid transparent;

  border-left: 1.6rem solid white;

  transition: border-color 0.15s linear;
`

const StyledColumnWrapper = styled.div`
  display: flex;
`
const thumbnailBackButtonStyles = css`
  ${({ disabled }) => disabled && "opacity: 0.2;"};
`
const StyledThumbnailButtonBack = styled(ButtonBack)`
  ${thumbnailBackButtonStyles};
`
const StyledThumbnailButtonNext = styled(ButtonNext)`
  ${thumbnailBackButtonStyles};
`

const StyledIcon = styled.object`
  width: 1.6rem;
  height: 1.6rem;
  margin-right: 1.6rem;
`

const StyledButtonContainer = styled.div`
  display: flex;
`

const StyledCaptionContainer = styled.div`
  height: max-content;
  max-height: 41rem;
  overflow: auto;

  ${({ theme }) => theme.mq.medium} {
    margin-right: -0.6rem;
    padding: 0 1rem 0 0;

    &::-webkit-scrollbar-track {
      background-color: ${({ theme }) => theme.color.secondary06};
    }

    &::-webkit-scrollbar {
      width: 6px;
      background-color: ${({ theme }) => theme.color.secondary06};
    }

    &::-webkit-scrollbar-thumb {
      background-color: ${({ theme }) => theme.color.secondary03};
    }
  }
`

const customStageListStyles = css`
  > div {
    display: flex;
    align-items: center;

    object {
      filter: invert(39%) sepia(13%) saturate(1065%) hue-rotate(178deg)
        brightness(92%) contrast(86%);
      transition: filter 0.5s;
    }
  }

  &:hover {
    > div object {
      filter: invert(6%) sepia(59%) saturate(1815%) hue-rotate(197deg)
        brightness(99%) contrast(99%);
    }
  }

  ${({ isActive }) =>
    isActive &&
    css`
      & > div object {
        filter: invert(6%) sepia(59%) saturate(1815%) hue-rotate(197deg)
          brightness(99%) contrast(99%);
      }
    `}
`

const StyledReactPlayer = styled(ReactPlayer)`
  &:hover > div > div:last-child {
    border-color: ${({ theme }) => theme.color.senary03};
  }

  &:hover > div > div:last-child div {
    border-left-color: ${({ theme }) => theme.color.senary03};
  }
`

export {
  titleStyles,
  captionStyles,
  StyledSection,
  StyledTabsWrapper,
  StyledContentWrapper,
  StyledContentColumn,
  StyledCrumbsWrapper,
  StyledButtonBack,
  StyledButtonNext,
  StyledBlock,
  StyledImage,
  StyledImageWrapper,
  StyledWrapper,
  StyledCaptionWrapper,
  StyledDotContainer,
  StyledThumbnailsWrapper,
  StyledButtonWrapper,
  StyledPlayButton,
  StyledTriangle,
  StyledColumnWrapper,
  StyledThumbnailButtonBack,
  StyledThumbnailButtonNext,
  StyledIcon,
  StyledButtonContainer,
  StyledCaptionContainer,
  customStageListStyles,
  StyledReactPlayer,
}
